import {defineMessages} from 'react-intl';

const namespaceForgot = 'password.forgot.';

export default defineMessages({
  forgottenPassword:{
    id: `${namespaceForgot}forgotten.password`,
    description: 'SepaHero Forgotten Password label',
    defaultMessage: 'SepaHero Forgotten Password',
  },
  forgotInstructions:{
    id: `${namespaceForgot}forgot.instructions`,
    description: 'Forgot Instructions label',
    defaultMessage: 'To recover your account, begin by entering your email below. We will send you an email with a link to proceed afterwards.',
  },
  successMessage:{
    id: `${namespaceForgot}success.message`,
    description: 'Success message label',
    defaultMessage: 'We send you an E-Mail to reset your password. Please check your Inbox.',
  }
})