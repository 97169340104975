import { customerId as getCustomerId } from './services/LoginService';
import {
    del,
    get,
    post,
    put,
} from './Requester';

export function preAppendLexofficeCoreWithCustomer(endpoint) {
    let customerId = getCustomerId();
    if (endpoint.startsWith('/')) {
        endpoint = endpoint.substring(1);
    }
    return 'lexoffice/core/' + customerId + '/' + endpoint;
}

export function getLexofficeCoreWithCustomerEndpoint(endpoint, opts = {checkAuthorization: true}) {
    return get(preAppendLexofficeCoreWithCustomer(endpoint), opts);
}

export function postLexofficeCoreWithCustomerEndpoint(endpoint, body = {}, opts = {checkAuthorization: true}) {
    return post(preAppendLexofficeCoreWithCustomer(endpoint), body, opts);
}

export function putLexofficeCoreWithCustomerEndpoint(endpoint, body = {}, opts = {checkAuthorization: true}) {
    return put(preAppendLexofficeCoreWithCustomer(endpoint), body, opts);
}

export function deleteLexofficeCoreWithCustomerEndpoint(endpoint, body = {}, opts = {checkAuthorization: true}) {
    return del(preAppendLexofficeCoreWithCustomer(endpoint), opts);
}
