import React, {Fragment, useState} from 'react';
import cx from "classnames";
import styles from "./styles.module.scss";
import {injectIntl} from "react-intl";

const FormField = (props) => {

	const [value, setValue] = useState('');
	const [dirty, setDirty] = useState(false);
	const [errors, setErrors] = useState([]);

	const { type, label, fieldId, children, expanded } = props;

	const hasErrors = errors.length > 0;
	const controlClass = [styles.formControl, dirty ? hasErrors ? 'is-invalid' : 'is-valid' : '' ].join(' ').trim();

	const hasChanged = e => {
		e.preventDefault();

		const { validator = f => f, onStateChanged = f => f } = props;

		const value = e.target.value;

		let errors = [];

		if ('function' === typeof validator) {
			try {
				if (validator(value)) setErrors([]);
			} catch (e) {
				errors = [ ...errors, e.message ];
			}
		}

		setValue(value);
		setErrors(errors);
		setDirty(!dirty || dirty);
		onStateChanged({ value, errors, dirty});
	}

	return (
			<Fragment>
				<div>
					<div className={cx(["d-flex flex-row justify-content-between align-items-center"])}>
						{expanded && <label htmlFor={fieldId} style={{position:"relative", margin: 0}}>{label}<span style={{color:'#f28383'}}>*</span></label>}
						{ hasErrors && <div className={cx([styles.error, styles.formHint, "text-right m-0 mb-2"])}>{ errors[0] }</div> }
					</div>
					{children}
					<input style={{border:0, outline: "none", width: "100%"}} type={type} className={controlClass} id={fieldId} value={value} onChange={hasChanged} />
				</div>
			</Fragment>
		);

}

export default injectIntl(FormField);
