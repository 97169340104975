import React from "react"
import {FormattedMessage, injectIntl} from "react-intl";
import Background from "../../images/compressed/AdobeStock_133775821.png";
import "../../Login/styles.module.scss";
import internationalisation from "./intl";
import SetNewPasswordForm from "./SetNewPasswordForm";
import useSetNewPassword from "./hooks";
import "react-lazy-load-image-component/src/effects/blur.css";
import {LazyLoadImage} from "react-lazy-load-image-component";
import formIntl from "../../Login/intl";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const SetNewPassword = ({history}) => {

  const {
    submitSuccess,
    handleSubmit
  } = useSetNewPassword();

  const navigate = useNavigate();
  
  return (
      <>
        <div className="d-lg-flex half">
          <div className="contents lorder-1 order-md-1">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-7">
                  <div className="mb-4">
                    <h3>
                      <FormattedMessage {...internationalisation.forgottenPassword} />
                    </h3>
                    <p className="mb-4">
                      <FormattedMessage {...internationalisation.forgotInstructions} />
                    </p>
                </div>
                {
                  submitSuccess ?
                    <>
                      <div>
                        <FormattedMessage {...internationalisation.successMessage} />
                      </div>
                      <Button
                          className="btn mt-4 d-flex justify-content-center align-items-center w-100"
                          onClick={() => navigate('/login')}>
                        <FormattedMessage {...formIntl.login} />
                      </Button>
                    </> :
                    <SetNewPasswordForm handleSubmit={handleSubmit} />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="bg order-2 order-md-2 d-none d-sm-block">
          <LazyLoadImage
            wrapperClassName={"h-100 d-inline-block"}
            style={{minHeight:'100%', maxWidth:'100%', maxHeight:'100%', objectFit: 'cover'}}
            effect="blur"
            src={Background}
          />
          </div>
      </div>
    </>
  )
}

export default injectIntl(SetNewPassword);
