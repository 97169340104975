import {defineMessages} from 'react-intl';

const namespaceReset = 'register.password.field.';

export default defineMessages({
  hint:{
    id: `${namespaceReset}hint`,
    description: 'Password Hint label',
    defaultMessage: 'Password must contain 10 characters, at least 2 special characters and 2 numbers. Common Passwords like \'password\' are also forbidden',
  },
  short:{
    id: `${namespaceReset}short`,
    description: 'Short label',
    defaultMessage: 'Password is short',
  },
  weak:{
    id: `${namespaceReset}weak`,
    description: 'Weak label',
    defaultMessage: 'Password is weak',
  },
})