import {get, post, put} from './Requester';

export function updateUser(user) {
    return new Promise((resolve, reject) => {
        put('users/' + user.id, user)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                console.log(error)
                reject(error);
            })
    })
}

export function getUser(id) {
    return new Promise((resolve, reject) => {
        get('users/' + id)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                console.log(error)
                reject(error);
            })
    })
}

export function createInvitedUser(user) {
    return new Promise((resolve, reject) => {
        post('register/accept-invitation', user)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error);
            })
    })
}

export function updateInvitedUser(user) {
    return new Promise((resolve, reject) => {
        put('users/' + user.id, user.data).then(response => {
            resolve(response.data)
        })
            .catch(error => {
                reject(error);
            })
    })
}

export function postForgottenPassword(email) {
    return new Promise((resolve, reject) => {
        post('register/forgotten-password', {email}).then(response => {
            resolve(response.data)
        })
            .catch(error => {
                reject(error);
            })
    })
}

export async function getForgottenPassword(jwtToken) {
    const response = await get(`register/forgotten-password?jwtToken=${jwtToken}`, {checkAuthorization: false});
    return response.data;
}

export function postChangePassword(jwtToken, password) {
    return new Promise((resolve, reject) => {
        post('register/change-password/', {jwtToken, password}).then(response => {
            resolve(response.data)
        })
            .catch(error => {
                reject(error);
            })
    })
}

