
export const startDelayedIsLoading = (isLoadingFunction) => {
  return startDelayedFunction(750,
      () => isLoadingFunction(true),
      () => isLoadingFunction(false));
}

export const startDelayedFunction = (timeout, onStartFunc, onEndFunc) => {
  let inProgress = true;

  const timerId = setTimeout(() => {
    if (inProgress) {
      onStartFunc();
    }
  }, timeout)

  return (callEndFunc = true) => {
    inProgress = false;

    clearTimeout(timerId);

    if (callEndFunc) {
      onEndFunc();
    }
  };
}