import React from 'react';
import {Provider} from "react-redux";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./js/Store";
import {IntlProvider} from "react-intl";
import messages_en from "./translations/en.json";
import messages_de from "./translations/de.json";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";

// addLocaleData([...locale_en, ...locale_de]);

const messages = {
  en: messages_en,
  de: messages_de,
};

let language = navigator.language.split(/[-_]/)[0];

const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
    <Provider store={store}>
      <IntlProvider locale={language} messages={messages[language]}>
        <React.StrictMode>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </React.StrictMode>
      </IntlProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
