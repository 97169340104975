import {useState} from 'react';

const useForm = (callback) => {
    const [changes, setChanges] = useState({});
    const handleFormSubmit = (event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      callback(event);
    }
    const handleInputChange = (event) => {
      const targetName = event.target.name;
      const targetValue = event.target.value;

      setChanges(changes => ({...changes, [targetName]: targetValue}));
    }
    const handleSelectChange = (name,value) => {
      setChanges(changes => ({...changes, [name]: value}));
    }
    return {
      changes,
      setChanges,
      handleInputChange,
      handleSelectChange,
      handleFormSubmit
    };
}

export default useForm;
