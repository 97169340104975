import React, {useEffect} from "react";
import {registerLexoffice} from "../../js/LexofficeCore";
import cx from "classnames";
import styles2 from "../ContentWrapper/styles.module.scss";
import styles from "../MainContent/styles.module.scss";
import Header from "../header/header";
import {Card, Row} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import internationalisation from "./intl";
import MaterialIcon from "material-icons-react";
import {useNavigate} from "react-router-dom";

const RedirectLexoffice = (props) => {

  const {intl} = props;

  const navigate = useNavigate();

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);

    const code = queryParameters.get("code");

    registerLexoffice(code)
    .then(() =>
        navigate("/app")
    )
    .catch((err) => {
      navigate("/app")
    });

  }, [navigate])

  return <div className={cx([styles2.blueBox])}>
    <Header/>
    <div className={cx([styles2.box])}>
      <div className={cx([styles2.blueBox])}></div>
      <div className={cx([styles2.contentBox])}>
        <div className={cx([styles2.contentHeader])}>
          <Row className={"pl-5"}>
            <div className={cx(["col-md-12"])}>
              <h5 className={cx("text-white")}>Dashboard {intl.formatMessage({...internationalisation.sepaHero})}</h5>
            </div>
            <ul className={cx(["breadcrumb", "bg-transparent", "text-white"])}>
              <li className={cx(["breadcrumb-item", styles2.firstBreadcrumb])}>
                <MaterialIcon icon="house_outline" color="white" size={"20"}/>
              </li>
              <li className={cx(["breadcrumb-item", styles2.lineColorWhite])}>{intl.formatMessage({...internationalisation.signUpWith})} Lexoffice</li>
            </ul>
          </Row>
        </div>



      </div>

      <Row className={cx([styles.boxOffset, "mx-3"])}>


          <div className={cx(["col-xl-12", "col-md-12"])}>

            <div className="col-xl-12 col-md-12">
              <Card className={cx([styles.cardStyle, "amount-card overflow-hidden"])}>
                <Card.Body className='p-5 mx-auto'>
              <div className="spinner-border text-primary" role="status"  style={{width: '8rem', height: '8rem'}}>
                <span className="sr-only"><FormattedMessage {...internationalisation.loading}/></span>
              </div>
                </Card.Body>
                <Card.Footer className="w-100 text-center"><h3><FormattedMessage {...internationalisation.weAreHandling}/></h3></Card.Footer>
              </Card>
            </div>

          </div>
      </Row>


    </div>



  </div>
}

export default injectIntl(RedirectLexoffice);
