import {get, post} from './Requester';

export function checkWhetherEmailExists(email) {
    const encodedEmail = encodeURI(email);
    return new Promise((resolve, reject) => {
        get(`register/check-email?email=${encodedEmail}`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function saveEmail(email) {
    let body = {
        email,
    };
    return new Promise((resolve, reject) => {
        post('register/marketing-user', body)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
