import { defineMessages } from 'react-intl';

const namespace = 'main.content.redirect.';

export default defineMessages({
  signUpWith: {
    id: `${namespace}sign.up.with`,
    description: 'Signup with Lexoffice label',
    defaultMessage: 'Signup with',
  },
  loading: {
    id: `${namespace}processed`,
    description: 'Loading... label',
    defaultMessage: 'Loading...',
  },
  weAreHandling: {
    id: `${namespace}open`,
    description: 'We are handling the authentication - and will forward you within the next 10 seconds. label',
    defaultMessage: 'We are handling the authentication - and will forward you within the next 10 seconds.',
  },
  sepaHero: {
    id: `${namespace}sepahero`,
    description: 'SepaHero word',
    defaultMessage: 'SepaHero',
  },
});
