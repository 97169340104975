import React, {Fragment, useState} from 'react';
import cx from "classnames";
import styles from "./styles.module.scss";
import {FormattedMessage, injectIntl} from "react-intl";
import internationalisation from "./intl";

import FormField from './FormField';
import {passwordStrength} from "check-password-strength";

const PasswordField = (props) => {

	const {propsMinStrength = 3, propsThresholdLength = 10} = props;

	const {
		intl,
		type,
		validator,
		label,
		expanded,
		onPasswordChanged,
		onStrengthChanged,
		children,
		...restProps
	} = props;
	const minStrength = typeof propsMinStrength === 'number'
			? Math.max(Math.min(propsMinStrength, 4), 0)
			: 4;

	const thresholdLength = typeof propsThresholdLength === 'number'
			? Math.max(propsThresholdLength, 10)
			: 10;

	const [password, setPassword] = useState('');
	const [strength, setStrength] = useState(0);

	const stateChanged = state => {
		const strengthScore = passwordStrength(state.value).id + 1;
		setPassword(state.value);
		setStrength(strengthScore);

		// set password and strenght in top level
		onPasswordChanged(state.value);
		onStrengthChanged(strengthScore);
	};

	const validatePasswordStrong = value => {
		if (value === '') {
			return true;
		}
		if (value.length < thresholdLength) {
			throw new Error(
					intl.formatMessage({...internationalisation.short}));
		}
		if (passwordStrength(value).id + 1 < minStrength) {

			throw new Error(
					intl.formatMessage({...internationalisation.weak}));
		}
	};

	const passwordLength = password.length;
	const passwordStrong = strength >= minStrength;
	const passwordLong = passwordLength > thresholdLength;

	const counterClass = ['badge badge-pill', passwordLong ? passwordStrong ? 'badge-success' : 'badge-warning' : 'badge-danger'].join(' ').trim();

	const strengthClass = [styles.strengthMeter, 'm-2', passwordLength > 0 ? 'visible' : 'invisible'].join(' ').trim();

	return (
		<div className={cx("d-block m-auto", styles.mainContainer)}>
			<div className={cx([styles.formContainer, "d-block position-relative align-middle"])} style={{width:'100%'}}>
				<form noValidate>
					<div>
						<Fragment>
						<div className={cx(["position-relative"])}>
							{!expanded && <label htmlFor="password">{label}<span style={{color:'#f28383'}}>*</span></label>}
								<FormField className={"mt-2"} type="password" label={label} expanded={expanded} validator={validatePasswordStrong} onStateChanged={stateChanged} {...restProps}>
									{ expanded && <span className={cx([styles.formInstructions,"d-block"])}><FormattedMessage {...internationalisation.hint}/></span>}
										{children}
								</FormField>

							{expanded &&
							<>
								<div className={strengthClass}>
									<div className={cx([styles.strengthMeterFill])} data-strength={strength}/>
								</div>
								<div className={cx([styles.passwordCount, "position-absolute mx-3"])}>
									<span className={counterClass}>{ passwordLength ? passwordLong ? `${thresholdLength}+` : passwordLength : '' }</span>
								</div>
							</>
							}
						</div>
						</Fragment>
					</div>
				</form>
			</div>
		</div>
	);
}

export default injectIntl(PasswordField);
