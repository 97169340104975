export const IS_LOADING = 'IS_LOADING';
export const REDIRECT = 'REDIRECT';
export const UPDATE_REGISTRATION_USER = 'UPDATE_REGISTRATION_USER';
export const UPDATE_REGISTRATION_CLIENT = 'UPDATE_REGISTRATION_CLIENT';
export const UPDATE_LOGIN_USER = 'UPDATE_LOGIN_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_STEP = 'UPDATE_STEP';
export const IS_INTERACTION_ENABLED = 'IS_INTERACTION_ENABLED';
export const UPDATE_REQUESTED_OPERATIONS = 'UPDATE_REQUESTED_OPERATIONS';
export const UPDATE_SHOW_UPGRADE_MODAL = 'UPDATE_SHOW_UPGRADE_MODAL';
export const PLAN_CHANGED_MODAL = 'PLAN_CHANGED_MODAL';
export const UPDATE_ERROR_MESSAGE = 'UPDATE_ERROR_MESSAGE';
export const UPDATE_ERROR_MODAL = 'UPDATE_ERROR_MODAL';
export const UPDATE_CONTACT_POPUP = 'UPDATE_CONTACT_POPUP';
export const UPDATE_DAYS_REMAINING = 'UPDATE_DAYS_REMAINING';
export const UPDATE_TRIAL_ENDED_POPUP = 'UPDATE_TRIAL_ENDED_POPUP';
export const UPDATE_TRIAL_REMAINING_POPUP = 'UPDATE_TRIAL_REMAINING_POPUP';
export const UPDATE_LOADED = 'UPDATE_LOADED';
export const UPDATE_GLOBAL_CANCEL_FUNCTION = 'UPDATE_GLOBAL_CANCEL_FUNCTION';
export const UPDATE_GLOBAL_ERROR_HANDLING_CHILDREN = 'UPDATE_GLOBAL_ERROR_HANDLING_CHILDREN';
export const UPDATE_REQUESTED_OPERATIONS_POPUP_INTL_TEXT_OBJECT = 'UPDATE_REQUESTED_OPERATIONS_POPUP_INTL_TEXT_OBJECT';
export const UPDATE_USER_CURRENT_CUSTOMER = 'UPDATE_USER_CURRENT_CUSTOMER'
