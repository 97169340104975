import {
  IS_LOADING,
  PLAN_CHANGED_MODAL,
  REDIRECT,
  UPDATE_CONTACT_POPUP,
  UPDATE_DAYS_REMAINING,
  UPDATE_ERROR_MESSAGE,
  UPDATE_ERROR_MODAL, UPDATE_GLOBAL_CANCEL_FUNCTION,
  UPDATE_GLOBAL_ERROR_HANDLING_CHILDREN,
  UPDATE_LOADED,
  UPDATE_LOGIN_USER,
  UPDATE_REGISTRATION_CLIENT,
  UPDATE_REGISTRATION_USER,
  UPDATE_SHOW_UPGRADE_MODAL,
  UPDATE_STEP,
  UPDATE_TRIAL_ENDED_POPUP,
  UPDATE_TRIAL_REMAINING_POPUP,
  UPDATE_USER,
  UPDATE_USER_CURRENT_CUSTOMER,
} from '../../constants/action-types';

export function updateRegisterStep(payload) {
  return {
    type: UPDATE_STEP,
    payload: payload,
  };
}

export function isLoading(payload) {
  return {
    type: IS_LOADING,
    payload: payload,
  };
}

export function redirect(destination, body) {
  return {
    type: REDIRECT,
    payload: {
      destination,
      body,
    },
  };
}

export function updateRegistrationUser(payload) {
  return {
    type: UPDATE_REGISTRATION_USER,
    payload: payload,
  };
}

export function updateRegistrationClient(payload) {
  return {
    type: UPDATE_REGISTRATION_CLIENT,
    payload: payload,
  };
}

export function updateLoginUser(payload) {
  return {
    type: UPDATE_LOGIN_USER,
    payload: payload,
  };
}

export function updateUser(payload) {
  return {
    type: UPDATE_USER,
    payload: payload,
  };
}

export function updateUserCurrentCustomer(payload) {
  return {
    type: UPDATE_USER_CURRENT_CUSTOMER,
    payload: payload,
  };
}

export function updateShowUpgradeModal(payload) {
  return {
    type: UPDATE_SHOW_UPGRADE_MODAL,
    payload: payload,
  };
}

export function updatePlanChanged(payload) {
  return {
    type: PLAN_CHANGED_MODAL,
    payload: payload,
  };
}

export function updateErrorMessage(payload) {
  return {
    type: UPDATE_ERROR_MESSAGE,
    payload: payload,
  };
}

export function updateErrorStatus(payload) {
  return {
    type: UPDATE_ERROR_MODAL,
    payload: payload,
  };
}

export function updateGlobalErrorHandlingChildren(payload) {
  return {
    type: UPDATE_GLOBAL_ERROR_HANDLING_CHILDREN,
    payload: payload,
  };
}


export function updateContactPopupStatus(payload) {
  return {
    type: UPDATE_CONTACT_POPUP,
    payload: payload,
  };
}

export function updateDaysRemaining(payload) {
  return {
    type: UPDATE_DAYS_REMAINING,
    payload: payload,
  };
}

export function updateTrialEndedPopup(payload) {
  return {
    type: UPDATE_TRIAL_ENDED_POPUP,
    payload: payload,
  };
}

export function updateTrialRemainingPopup(payload) {
  return {
    type: UPDATE_TRIAL_REMAINING_POPUP,
    payload: payload,
  };
}

export function updateLoadedStatus(payload) {
  return {
    type: UPDATE_LOADED,
    payload: payload,
  };
}

export function updateGlobalCancelFunction(payload) {
  return {
    type: UPDATE_GLOBAL_CANCEL_FUNCTION,
    payload: payload,
  };
}

