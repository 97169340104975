import {defineMessages} from 'react-intl';

const namespaceForgot = 'password.forgot.';

export default defineMessages({
  email: {
    id: `${namespaceForgot}email`,
    description: 'Email field label',
    defaultMessage: 'Email',
  },
  resetPassword:{
    id: `${namespaceForgot}reset.password`,
    description: 'Reset Password button',
    defaultMessage: 'Reset Password',
  }
})