import {defineMessages} from 'react-intl';

const namespaceReset = 'password.reset.';

export default defineMessages({
  passwordReset:{
    id: `${namespaceReset}password.reset`,
    description: 'SepaHero Password Reset label',
    defaultMessage: 'SepaHero Password Reset',
  },
  resetInstructions:{
    id: `${namespaceReset}reset.instructions`,
    description: 'Reset Instructions label',
    defaultMessage: 'To recover your account, begin by entering a new password below.',
  },
  tokenExpired:{
    id: `${namespaceReset}token.expired`,
    description: 'Token Expired message',
    defaultMessage: 'Your password reset request has expired! Please generate a new one by following this link:',
  },
  forgottenPasswordLink:{
    id: `${namespaceReset}forgotten.password.link`,
    description: 'Forgotten Password Link message',
    defaultMessage: 'Forgotten Password',
  },
})