import {defineMessages} from 'react-intl';

const namespaceLogin = 'login.';
const namespaceRegister = 'register.page.';

export default defineMessages({
  loginTitle: {
    id: `${namespaceLogin}login.title`,
    description: 'Login Title',
    defaultMessage: 'SepaHero Login',
  },
  email: {
    id: `${namespaceLogin}email`,
    description: 'Email',
    defaultMessage: 'Email',
  },
  stayLoggedin: {
    id: `${namespaceLogin}stay.logged.in`,
    description: 'Stay logged in',
    defaultMessage: 'Stay logged in',
  },
  passwordForgotten: {
    id: `${namespaceLogin}password.forgotten`,
    description: 'Passwort vergessen',
    defaultMessage: 'Forgotten Password',
  },
  login: {
    id: `${namespaceLogin}login.button`,
    description: 'Login Button',
    defaultMessage: 'Login',
  },
  register: {
    id: `${namespaceLogin}register.button`,
    description: 'Register Button',
    defaultMessage: 'Register',
  },
  passwortLabel: {
    id: `${namespaceLogin}password.label`,
    description: 'Password Label',
    defaultMessage: 'Password',
  },
  usernameLabel: {
    id: `${namespaceLogin}username.label`,
    description: 'Username Label',
    defaultMessage: 'Username',
  },
  tagLine: {
    id: `${namespaceLogin}tag.line`,
    description: 'TagLine Text',
    defaultMessage: 'Sepa direct debit in next to no time! We link Lexoffice with GoCardless.',
  },
  registerTitle: {
    id: `${namespaceRegister}register.title`,
    description: 'Register Title Label',
    defaultMessage: 'SepaHero Register',
  },
  company: {
    id: `${namespaceRegister}company`,
    description: 'Company Label',
    defaultMessage: 'Company',
  },
  name: {
    id: `${namespaceRegister}name`,
    description: 'Name Label',
    defaultMessage: 'Name',
  },
  city: {
    id: `${namespaceRegister}city`,
    description: 'City Label',
    defaultMessage: 'City',
  },
  zip: {
    id: `${namespaceRegister}zip`,
    description: 'Zip Label',
    defaultMessage: 'Zip',
  },
  address: {
    id: `${namespaceRegister}address`,
    description: 'Address Label',
    defaultMessage: 'Address',
  },
  country: {
    id: `${namespaceRegister}country`,
    description: 'Country Label',
    defaultMessage: 'Country',
  },
  iAgree: {
    id: `${namespaceRegister}i.agree`,
    description: 'I accept the Terms and Conditions Label',
    defaultMessage: 'I have read the terms and conditions and privacy policy and agree.',
  },
  termsAndConditions: {
    id: `${namespaceRegister}terms.and.conditions`,
    description: 'Terms and Conditions Label',
    defaultMessage: 'Terms and Conditions',
  },
  privacyPolicy: {
    id: `${namespaceRegister}privacy.policy`,
    description: 'Privacy Policy Label',
    defaultMessage: 'Privacy Policy',
  },
  orLabel: {
    id: `${namespaceLogin}or.label`,
    description: 'or Label',
    defaultMessage: 'or',
  },

})