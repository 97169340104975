import {
  getLexofficeCoreWithCustomerEndpoint,
  postLexofficeCoreWithCustomerEndpoint
} from "./LexofficeCoreRequester";
import {get} from './Requester';

export function registerLexoffice(code) {
  return new Promise((resolve, reject) => {
    get('connect/lexoffice/?code=' + code).then((response) => {
      resolve(response.data);
    })
        .catch((error) => {
          reject(error);
        });
  });
}

export function getVoucherList(page, pageSize) {
  return new Promise((resolve, reject) => {
    postLexofficeCoreWithCustomerEndpoint(
        'voucherList?page=' + page + '&size=' + pageSize)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export function transferInvoiceByIdBackend(invoiceId) {
  return new Promise((resolve, reject) => {
    postLexofficeCoreWithCustomerEndpoint(
        'transfer/invoice/without/check/' + invoiceId)
    .catch((error) => {
      reject(error);
    });
  });
}

export function getPaymentConditions() {
    // console.log("getPaymentConditions");
  return new Promise((resolve, reject) => {
    getLexofficeCoreWithCustomerEndpoint(`paymentCondition/list`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
  });
}

export function getPaymentCondition() {
  return new Promise((resolve, reject) => {
    getLexofficeCoreWithCustomerEndpoint(`paymentCondition`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
  });
}

export function getPaymentConditionV2() {
  return new Promise((resolve, reject) => {
    getLexofficeCoreWithCustomerEndpoint(`v2/paymentCondition`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
  });
}

export function postProfileLexoffice() {
  return new Promise((resolve, reject) => {
    postLexofficeCoreWithCustomerEndpoint(`profile`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
  });
}
