import { defineMessages } from 'react-intl';

const namespace = 'service.down.';

export default defineMessages({
  offline: {
    id: `${namespace}offline`,
    description: 'Offline text',
    defaultMessage: 'Offline',
  },
  siteIsDown: {
    id: `${namespace}site.is.down`,
    description: 'Site is down text',
    defaultMessage: 'The site is temporarily down',
  },
  reload: {
    id: `${namespace}reload`,
    description: 'Reload button text',
    defaultMessage: 'Reload',
  }
});
