import useForm from "../../../../hooks/common/form";
import {useState} from "react";

const useResetPasswordForm = (handleSubmit, messages) => {

  const [error, setError] = useState();
  const {
    changes,
    handleInputChange,
    handleFormSubmit
  } = useForm(event => {
    if (event.target.checkValidity() === false) {
      setError({
        errorMessageId: messages["passwordIsRequired"],
        showMissingInfoErrorMessage: true,
      });
      return;
    }
    if (changes.password !== changes.confirmPassword) {
      setError({
        errorMessageId: messages["passwordsNotMatching"],
        showMissingInfoErrorMessage: true,
      });
      return;
    }
    handleSubmit({password:changes.password, confirmPassword:changes.confirmPassword});
  });

  return {
    error,
    password: changes?.password,
    confirmPassword: changes?.confirmPassword,
    handleInputChange,
    handleFormSubmit
  };
}

export default useResetPasswordForm;