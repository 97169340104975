import cx from "classnames";
import {FormattedMessage, injectIntl} from "react-intl";
import internationalisation from "./intl";
import formIntl from "../../../Login/intl";
import {Button} from "react-bootstrap";
import React from "react";
import useResetPasswordForm from "./hooks";
import {useNavigate} from "react-router-dom";

const ResetPasswordForm = ({ handleSubmit }) => {

  const messages = {
    passwordsNotMatching: "password.reset.passwords.not.matching",
    passwordIsRequired: "password.reset.password.is.required"
  };

  const navigate = useNavigate();

  const {
    password,
    confirmPassword,
    handleInputChange,
    handleFormSubmit,
    error
  } = useResetPasswordForm(handleSubmit, messages);

  const fieldNotEmpty = () => (password?.length || confirmPassword?.length)
      ? 'field--not-empty' : '';

  return (
    <form className={'needs-validation'} noValidate onSubmit={handleFormSubmit}>
      <div className={cx(["form-group first", fieldNotEmpty()])}>
        <label htmlFor="password"><FormattedMessage {...internationalisation.password} /></label>
        <input type="password" className="form-control" id="password" name="password" required onChange={handleInputChange} />
      </div>
      <div className={cx(["form-group last mb-3", fieldNotEmpty()])}>
        <label htmlFor="confirmPassword"><FormattedMessage {...internationalisation.confirmPassword} /></label>
        <input type="password" className="form-control" id="confirmPassword" name="confirmPassword" required onChange={handleInputChange} />
      </div>
      {error && (<div className="alert alert-warning">
        <FormattedMessage id={"" + error.errorMessageId} />
      </div>)
      }

      <button type="submit" className="btn btn-block btn-primary"><FormattedMessage {...internationalisation.saveNewPassword} /></button>
      <span className="d-block text-center my-4 text-muted">&mdash;<FormattedMessage {...formIntl.orLabel} />&mdash;</span>

      <div className="social-login">
        <Button
            className="btn d-flex justify-content-center align-items-center w-100"
            onClick={() => navigate(
                '/login')}><FormattedMessage {...formIntl.login} /></Button>
      </div>
    </form>
  )
}

export default injectIntl(ResetPasswordForm);
