import './App.css';
import React, {Suspense} from 'react'
import {Route, Routes} from 'react-router-dom'
import ConsentWrapper from "./components/ConsentWrapper/ConsentWrapper";
import ServiceDown from "./components/ServiceDown";
import {PrivateRoute} from "./components/PrivateRoute/PrivateRoute"
import RedirectGoCardless from "./components/Redirect/RedirectGoCardless";
import RedirectLexoffice from "./components/Redirect/RedirectLexoffice";
import GoCardlessUnauthenticated from "./components/GoCardlessUnauthenticated";
import LexofficeUnauthenticated from "./components/ErpUnauthenticated";
import Login from "./components/Login";
import Register from "./components/Register";
import ResetPassword from "./components/Register/ResetPassword";
import SetNewPassword from "./components/Register/SetNewPassword";
import MainApp from "./MainApp";
import LoginService from "./components/Login/LoginService";
import ErpUnauthenticated from "./components/ErpUnauthenticated";
import 'nps-hero/dist/index.css'
import 'nps-hero/dist/styles.css'

function App() {

  return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/api/connect/lexoffice/*"
                   element={<RedirectLexoffice/>}/>
            <Route path="/api/connect/goCardless/*"
                   element={<RedirectGoCardless/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/service/login" element={<LoginService/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/reset-password" element={<ResetPassword/>}/>
            <Route path="/forgotten-password" element={<SetNewPassword/>}/>
            <Route path="/service-down" element={<ServiceDown/>}/>
            <Route path="/GoCardlessUnauthenticated"
                   element={<GoCardlessUnauthenticated/>}/>
            <Route path="/erpUnauthenticated"
                   element={<ErpUnauthenticated/>}/>

            <Route
                path="/app/*"
                element={
                  <React.Suspense fallback={<div>Loading ...</div>}>
                    <PrivateRoute>
                      <MainApp/>
                    </PrivateRoute>
                  </React.Suspense>
                }
            />

            <Route path="*" element={
              <PrivateRoute>
                <MainApp/>
              </PrivateRoute>
            }/>
          </Routes>
        </Suspense>
        <ConsentWrapper/>
      </div>
  );
}

export default App

