const initialState = {
    isLoading: false,
    isUserInteractive: true,
    showUpgradeModal: false,
    planChanged: false,
    registrationClient: {
        name: '',
        companySize: '',
        customerUse: '',
        workingOn: '',
        id: '',
        invitationMails: []
    },
    registrationUser: {
        email: '',
        name: '',
        phone: '',
        id: '',
        position: '',
        termsAndConditions: ''
    },
    loginUser: {
        email: '',
        password: ''
    },
    user: {
        id: '',
        email: '',
        name: '',
        acceptedTermsOfService: null,
        lastRegistrationStep: null,
        customers: [],
        currentCustomer: {
            logoId: undefined,
        },
    },
    customers: [],
    redirect: '',
    registerStep:'',
    requestedOperations: [],
    requestedOperationsIntlTextObject: undefined,
    showError: false,
    errorMessage: '',
    showContactPopup: false,
    daysRemaining: 0,
    showTrialRemainingPopup: false,
    showTrialEndedPopup: false,
    loaded: false,
    globalErrorHandleChildren: undefined,
};

export default initialState
