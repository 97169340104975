import React from 'react';
import {isJwtTokenExpired} from "../../js/Common";
import {jwtToken} from "../../js/services/LoginService";
import {Navigate, useLocation} from "react-router-dom";

export const PrivateRoute = ({children}) => {
  const tokenExpired = isJwtTokenExpired(jwtToken());

  const location = useLocation();

  let redirectPath = "/login";

  if(location.pathname && location.pathname !== "/app"){
    redirectPath = "/login?path=" + location.pathname;
  }

  return !tokenExpired ? children : <Navigate to={redirectPath}/>;
}
