import rootReducer from './Reducers';


export const initialState = {
    selectedAction: undefined,
};

const globalReducer = (state = initialState, action) => {
    return {
        root: rootReducer(state.root, action),

    };
};

export default globalReducer;
