import React, {useState} from 'react';
import {Button} from "react-bootstrap";
import {FormattedMessage} from 'react-intl';
import styles from './styles.module.scss';
import internationalization from './intl';
import Background from "../images/compressed/AdobeStock_133774976.png";
import {startDelayedIsLoading} from "../../hooks/common/loading";
import {
  loginUser as login,
  setStayLoggedInPreference,
  stayLoggedInPreference
} from "../../js/services/LoginService";
import {connect} from "react-redux";
import {
  isLoading,
  updateErrorMessage,
  updateErrorStatus
} from '../../js/actions/index';
import cx from "classnames";
import "react-lazy-load-image-component/src/effects/blur.css";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {useNavigate} from "react-router-dom";

const Login = (props) => {

  const [email, setEmail] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const [fieldNotEmpty, setFieldNotEmpty] = useState("field--not-empty");
  const [error, setError] = useState();
  const [stayLoggedIn, setStayLoggedIn] = useState(stayLoggedInPreference());

  const navigate = useNavigate();

  const redirectRegister = () => {
    navigate('/register');
  }

  const clearPassword = () => {
    setPassword(undefined);
  }

  const passwordForgotten = () => {
    navigate('/forgotten-password');
  }

  const onSubmitPasswordForm = () => {
    const stopDelayedIsLoading = startDelayedIsLoading(props.isLoading);

    const loginObj = {
      email: email,
      password: password,
    };

    clearPassword();

    login(loginObj)
        .then(() => {
          stopDelayedIsLoading();
          navigate('/app');

        })
        .catch(() => {
          stopDelayedIsLoading();

          setError({
            errorMessageId: "unified.register.password.error",
            showMissingInfoErrorMessage: true,
          });
        })
  }

  const handleFieldChange = () => {
    if (email || password) {
      setFieldNotEmpty("field--not-empty");
    } else {
      setFieldNotEmpty(undefined);
    }
  }

  return (
      <>
        <div className="d-lg-flex half">
          <div className="contents lorder-1 order-md-1">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-7">
                  <div className="mb-4">
                    <h3><FormattedMessage {...internationalization.loginTitle}/></h3>
                    <p className="mb-4"><FormattedMessage {...internationalization.tagLine}/></p>
                  </div>

                  <div className={cx(["form-group first", fieldNotEmpty])}>
                    <label htmlFor="email"><FormattedMessage {...internationalization.email} /></label>
                    <input type="text" className="form-control" id="email" onChange={(e) => {
                      setEmail(e.target.value);
                      handleFieldChange();
                    }} ref={(input) => {
                      let value = input ? input.value : undefined;
                      setEmail(value)
                      handleFieldChange()
                    }}/>
                  </div>
                  <div className={cx(["form-group last mb-3", fieldNotEmpty])}>
                    <label htmlFor="password"><FormattedMessage {...internationalization.passwortLabel}/></label>
                    <input type="password" className="form-control" id="password" onChange={(e) => {
                      setPassword(e.target.value);
                      handleFieldChange()
                    }}
                           ref={(input) => {
                             let value = input ? input.value : undefined;
                             setPassword(value)
                             handleFieldChange()
                           }}/>
                  </div>
                  <div className="d-flex mb-5 align-items-center">
                    <label className="control control--checkbox mb-0"><span
                        className="caption"><FormattedMessage {...internationalization.stayLoggedin}/></span>
                      <input type="checkbox" checked={stayLoggedIn} onChange={() =>
                        setStayLoggedIn(previous => {
                          setStayLoggedInPreference(!previous);
                          return !previous;
                        })}/>
                      <div className="control__indicator">{}</div>
                    </label>
                    <span className="ml-auto"><button onClick={() => passwordForgotten()}
                                                      className={cx(
                                                          [styles.buttonNoDecoration])}><FormattedMessage {...internationalization.passwordForgotten}/></button></span>
                  </div>
                  {error && (<div className="alert alert-warning">
                    <FormattedMessage id={"" + error.errorMessageId}/>
                  </div>)
                  }

                  <button id="login" className="btn btn-block btn-primary" onClick={() => onSubmitPasswordForm()}>
                    <FormattedMessage {...internationalization.login}/></button>
                  <span className="d-block text-center my-4 text-muted">&mdash; <FormattedMessage {...internationalization.orLabel}/> &mdash;</span>

                  <div className="social-login">
                    <Button className="btn d-flex justify-content-center align-items-center w-100"
                            onClick={() => redirectRegister()}>
                      <FormattedMessage {...internationalization.register}/>
                    </Button>

                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="bg order-2 order-md-2 d-none d-lg-block">
            <LazyLoadImage
              wrapperClassName={"h-100 d-inline-block"}
              style={{minHeight:'100%', maxWidth:'100%', maxHeight:'100%', objectFit: 'cover'}}
              effect="blur"
              src={Background}
            />
          </div>

        </div>

      </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    isLoading: loading => dispatch(isLoading(loading)),
    updateErrorStatus: status => dispatch(updateErrorStatus(status)),
    updateErrorMessage: message => dispatch(updateErrorMessage(message)),
  }
}
const mapStateToProps = state => {
  return {
    showError: state.root.showError,
    errorMessage: state.root.errorMessage,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
