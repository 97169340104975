import React, {useMemo, useState} from "react"
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";
import {Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {checkWhetherEmailExists} from "../../js/EmailService";
import {
  checkCityAndCompany,
  createUserAndCustomer
} from "../../js/CustomerService";
import {updateUser} from "../../js/User";
import {redirect} from "../../js/Actions";
import internationalization from "../Login/intl";
import Background from "../images/compressed/AdobeStock_133775821.png";
import {useNavigate} from "react-router-dom";
import countryList from "react-select-country-list";
import Select from "react-select";
import {updateCredentialsStorage} from "../../js/services/LoginService";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import PasswordField from "./PasswordField";
import styles from "./styles.module.scss";
import MaterialIcon from "material-icons-react";
import {Switch} from "antd";

const RegisterComponent = (props) => {

  const [monthly, setMonthly] = useState(true);

  const [email, setEmail] = useState(undefined);
  const [name, setName] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const [passwordStrength, setPasswordStrength] = useState(undefined);
  const [company, setCompany] = useState(undefined);
  const [city, setCity] = useState(undefined);
  const {intl} = props;
  const [countryCode, setCountryCode] = useState(intl.locale);
  const options = useMemo(() => countryList().getData(), []);

  const handleCountryChange = (event) => {
    setCountryCode(event.value);
  }

  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [error, setError] = useState(undefined);
  const [fieldNotEmpty, setFieldNotEmpty] = useState();

  const checkFieldsAreCompleted = () => {
    if (!(name && company && city && countryCode && email)) {
      setError({
        errorMessageId: messages["pleaseFillAllFields"],
        showMissingInfoErrorMessage: true,
      });
      return false;
    }
    if (!termsAndConditions) {
      setError({
        errorMessageId: messages["termsAndConditions"],
        showMissingInfoErrorMessage: true,
      });
      return false;
    }
    if (passwordStrength < 4) {
      setError({
        errorMessageId: messages["passwordMustBeStrong"],
        showMissingInfoErrorMessage: true,
      });
      return false;
    }
    return true;
  }

  const handleSubmit = async () => {
    // 1. check if all fields have values
    if (!checkFieldsAreCompleted()) return;

    // 2. check if Email already exists
    const emailExists = await checkWhetherEmailExists(email);
    if (emailExists.registered) {
      setError({
        errorMessageId: messages["alreadyRegistered"],
        showMissingInfoErrorMessage: true,
      });
      return;
    }

    // 3. check if the city and company name match
    const cityAndCompany = await checkCityAndCompany(city, company);
    if (cityAndCompany.registered) {
      setError({
        errorMessageId: messages["matchingCompanyExists"],
        showMissingInfoErrorMessage: true,
      });
      return;
    }

    // 4. create new user if everything is ok
    handleCustomerUserCreate();
  }

  const handleFieldChange= () => {
    if(email || name || company || city || password){
      setFieldNotEmpty("field--not-empty");
    }
    else{
      setFieldNotEmpty(undefined);
    }
  }

  const navigate = useNavigate();

  const redirectLogin = () => {
    navigate('/login')
  };

  const messages = {
    name: "unified.register.name.error",
    email: "unified.register.email.error",
    password: "unified.register.password.error",
    termsAndConditions: "unified.register.terms.and.conditions.error",
    unableToRegisterMail: "unified.register.error.unable.to.save.mail",
    unableToUpdateUser: "unified.register.error.unable.to.update.user",
    alreadyRegistered: "unified.register.error.already.registered",
    unableToCheckMail: "unified.register.error.unable.to.check.mail",
    pleaseFillAllFields: "unified.register.error.please.fill.all.fields",
    matchingCompanyExists: "unified.register.error.matching.company.exists",
    passwordMustBeStrong: "unified.register.error.password.must.be.strong",
  };


  const handleCustomerUserCreate = () => {
    let customer = {};
    customer.name = name;
    customer.email = email;
    customer.password = password;
    customer.companyName = company;
    customer.city = city;
    customer.vatId = "";
    customer.countryCode = countryCode;

    createUserAndCustomer(customer)
      .then((response) => {
        updateCredentialsStorage({
          jwtToken: response?.jwtToken,
          userId: response?.userId,
          customerId: response?.customerId
        });
        navigate('/app');
        // 6. service to update user, as didn't have such at first
        handleUserUpdate(response);
      })
      .catch(() => {
        setError({
          errorMessageId: messages["unableToRegisterMail"],
          showMissingInfoErrorMessage: true,
        });
      });
  }

  const handleUserUpdate = (response) => {
    let user = {
      id: response.userId,
      acceptedTermsOfService: termsAndConditions,
      lastRegistrationStep: "step_seven",
    };

    updateUser(user)
      .then(() => {

        this.props.redirect("/app");

      })
      .catch(() => {
        setError({
          errorMessageId: messages["unableToUpdateUser"],
          showMissingInfoErrorMessage: true,
        });
      });
  };

  return (
      <div className="d-lg-flex half">
        <div className={cx("contents lorder-1 order-md-1", styles.wrapperRegister)}>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-7 mb-4">
                <div className="mb-3 mt-3">
                  <h3><FormattedMessage {...internationalization.registerTitle}/></h3>
                  <p className="mb-3"><FormattedMessage {...internationalization.tagLine}/></p>
                </div>

                <div className={cx(["form-group first", fieldNotEmpty])} style={{padding: 7}}>
                  <label htmlFor="name"><FormattedMessage {...internationalization.name} /><span
                      className={cx([styles.requiredFieldStar])}>*</span></label>
                  <input type="text" className="form-control" id="name" onChange={(e) => {
                    setName(e.target.value);
                    handleFieldChange(!!e.target.value)
                  }} ref={(input) => {
                    let value = input ? input.value : undefined;
                    setName(value)
                    handleFieldChange(value);
                  }}/>
                </div>

                <div className={cx(["form-group", fieldNotEmpty])} style={{padding: 7}}>
                  <label htmlFor="company"><FormattedMessage {...internationalization.company} /><span className={cx([styles.requiredFieldStar])}>*</span></label>
                  <input type="text" className="form-control" id="company" onChange={(e) => {
                    setCompany(e.target.value);
                    handleFieldChange(e.target.value)

                  }} ref={(input) => {
                    let value = input ? input.value : undefined;
                    setCompany(value)
                    handleFieldChange(value);
                  }}/>
                </div>

                <div className={cx(["form-group", fieldNotEmpty])} style={{padding: 7}}>
                  <label htmlFor="city"><FormattedMessage {...internationalization.city} /><span className={cx([styles.requiredFieldStar])}>*</span></label>
                  <input type="text" className="form-control" id="city" onChange={(e) => {
                    setCity(e.target.value);
                    handleFieldChange(e.target.value)

                  }} ref={(input) => {
                    let value = input ? input.value : undefined;
                    setCity(value)
                    handleFieldChange(value);
                  }}/>
                </div>

                <div className={cx(["form-group", "field--not-empty"])} style={{padding: 7}}>
                  <label htmlFor="country"><FormattedMessage {...internationalization.country} /><span
                      className={cx([styles.requiredFieldStar])}>*</span></label>
                  <div className={cx(styles.selectCountryBarPosition)}>
                    <Select id="country" menuPosition={'fixed'} value={{value: countryCode, label: countryList().getLabel(countryCode)}}
                            options={options} onChange={(e) => {
                      handleCountryChange(e);
                    }}/>
                  </div>
                </div>

                <div className={cx(["form-group", fieldNotEmpty])} style={{padding: 7}}>
                  <label htmlFor="email"><FormattedMessage {...internationalization.email} /><span className={cx([styles.requiredFieldStar])}>*</span></label>
                  <input type="text" className="form-control" id="email" onChange={(e) => {
                    setEmail(e.target.value);
                    handleFieldChange(e.target.value)

                  }} ref={(input) => {
                    let value = input ? input.value : undefined;
                    setEmail(value)
                    handleFieldChange(value);
                  }}/>
                </div>
                <div className={cx(["mb-3 py-3 form-group last", fieldNotEmpty])} style={{position: "relative", paddingLeft: 7, paddingRight: 7}}>
                  <PasswordField expanded={fieldNotEmpty} fieldId="password" label={intl.formatMessage({...internationalization.passwortLabel})} onPasswordChanged={setPassword} onStrengthChanged={setPasswordStrength} propsThresholdLength={10} propsMinStrength={4} required />
                </div>

                {error && (<div className="alert alert-warning">
                  <FormattedMessage id={""+error.errorMessageId}/>
                </div>)
                }

                <div className="d-flex mb-3 align-items-center">
                  <label className="control control--checkbox mb-0">
                    <span className="caption">
                      <FormattedMessage {...internationalization.iAgree}
                                        values={{
                                          terms:
                                              <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://d-automation.de/pdfs/AGB.pdf"
                                              >
                                                <FormattedMessage {...internationalization.termsAndConditions} />
                                              </a>
                                          ,
                                          privacy:
                                              <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://d-automation.de/pdfs/Datenschutzvereinbarung.pdf"
                                              >
                                                <FormattedMessage {...internationalization.privacyPolicy} />
                                              </a>
                                          ,
                                        }}
                      /><span className={cx([styles.requiredFieldStar])}>*</span>
                    </span>
                    <input type="checkbox" checked={termsAndConditions} onChange={() => setTermsAndConditions(!termsAndConditions)}/>
                    <div className="control__indicator">{}</div>
                  </label>
                </div>
                <div className="social-login">
                  <Button className="btn d-flex justify-content-center align-items-center w-100"
                          onClick={() => handleSubmit()}>
                    <FormattedMessage {...internationalization.register}/>
                  </Button>

                </div>
                <span className="d-block text-center my-4 text-muted">&mdash; <FormattedMessage {...internationalization.orLabel} /> &mdash;</span>
                <button id="login" className="btn btn-block btn-primary mb-4" onClick={() => redirectLogin()}>
                  <FormattedMessage {...internationalization.login}/></button>
              </div>
            </div>
          </div>
        </div>
        <div className={cx("bg order-2 order-md-2 d-none d-lg-block", styles.imageContainer)}>

          <LazyLoadImage
              wrapperClassName={"h-100 d-inline-block"}
              style={{height: '100%', maxWidth: '100%', maxHeight: '100%', objectFit: 'cover'}}
              effect="blur"
              src={Background}
          />
          <div className={cx(styles.imageOverlay, "col-xl-12 col-xxl-10")} id="generic_price_table">
            <div className={"container"}>
            <div className={"d-flex flex-row justify-content-center align-items-center bg-light-60 my-2 rounded p-2"}>
              <Switch onChange={() => setMonthly(!monthly)} className="mx-3"/> <h3 className={"font-weight-bolder m-0"}>
              {monthly && <FormattedMessage id={"onboard.stages.payment.plan.month.plan"}/>}
              {!monthly && <FormattedMessage id={"onboard.stages.payment.plan.year.plan"}/>}
            </h3>
            </div>
              <div className={"row h-auto"}>
                <div className={"col-md-6"}>
                  <div className="generic_content clearfix">

                    <div className="generic_head_price clearfix">

                      <div className="generic_head_content clearfix">

                        <div className="head_bg"></div>
                        <div className="head">
                          <span><FormattedMessage id={"onboard.stages.payment.plan.standard.plan"}/></span>
                        </div>

                      </div>

                      <div className="generic_price_tag clearfix">
                        <div className="generic_price_tag clearfix">
                          {monthly && (
                              <span className="price">
                              <span className="sign">€</span>
                              <span className="currency">13,90</span>
                              <span className="month">/<FormattedMessage
                                  id={"onboard.stages.payment.plan.month.short"}/></span>
                            </span>
                          )}
                          {!monthly && (
                              <span className="price">
                              <span className="sign">€</span>
                              <span className="currency">150</span>
                              <span className="month">/<FormattedMessage
                                  id={"onboard.stages.payment.plan.year.short"}/></span>
                          </span>
                          )}
                        </div>
                      </div>


                    </div>

                    <div className="generic_feature_list p-2">
                      <ul>
                        <li><span className={"pr-2"}><MaterialIcon
                            icon={"check"} size={18}/></span><FormattedMessage
                            id={"register.pricing.table.usp1"}/></li>
                        <li><span className={"pr-2"}><MaterialIcon
                            icon={"check"} size={18}/></span><FormattedMessage
                            id={"register.pricing.table.usp2"}/></li>
                        <li><span className={"pr-2"}><MaterialIcon
                            icon={"check"} size={18}/></span><FormattedMessage
                            id={"register.pricing.table.usp3"}/></li>
                        <li><span className={"pr-2"}><MaterialIcon
                            icon={"check"} size={18}/></span><FormattedMessage
                            id={"register.pricing.table.usp4"}/></li>
                        <li><span className={"pr-2"}><MaterialIcon icon={"check"} size={18}/></span><FormattedMessage
                            id={"register.pricing.table.usp5"}/></li>
                      </ul>
                    </div>

                  </div>
                </div>

                <div className={"col-md-6"}>
                  <div className="generic_content active clearfix">

                    <div className="generic_head_price clearfix">

                      <div className="generic_head_content clearfix">

                        <div className="head_bg"></div>
                        <div className="head">
                          <span><FormattedMessage id={"onboard.stages.payment.plan.premium.plan"}/></span>
                        </div>

                      </div>

                      <div className="generic_price_tag clearfix">
                        {monthly && (
                            <span className="price">
                              <span className="sign">€</span>
                              <span className="currency">25,90</span>
                              <span className="month">/<FormattedMessage id={"onboard.stages.payment.plan.month.short"}/></span>
                            </span>
                        )}
                        {!monthly && (
                          <span className="price">
                              <span className="sign">€</span>
                              <span className="currency">279</span>
                              <span className="month">/<FormattedMessage id={"onboard.stages.payment.plan.year.short"}/></span>
                          </span>
                        )}
                      </div>

                    </div>

                    <div className="generic_feature_list p-2">
                      <ul>
                        <li><span className={"pr-2"}><MaterialIcon
                            icon={"check"} size={18}/></span><FormattedMessage
                            id={"onboard.stages.payment.plan.premium.usp1"}/></li>
                        <li><span className={"pr-2"}><MaterialIcon
                            icon={"check"} size={18}/></span><FormattedMessage
                            id={"onboard.stages.payment.plan.premium.usp2"}/></li>
                        <li><span className={"pr-2"}><MaterialIcon
                            icon={"check"} size={18}/></span><FormattedMessage
                            id={"onboard.stages.payment.plan.premium.usp3"}/></li>
                        <li><span className={"pr-2"}><MaterialIcon
                            icon={"check"} size={18}/></span><FormattedMessage
                            id={"onboard.stages.payment.plan.premium.usp6"}/></li>
                        <li><span className={"pr-2"}><MaterialIcon
                            icon={"check"} size={18}/></span><FormattedMessage
                            id={"onboard.stages.payment.plan.premium.usp4"}/></li>
                        <li><span className={"pr-2"}><MaterialIcon
                            icon={"check"} size={18}/></span><FormattedMessage
                            id={"onboard.stages.payment.plan.premium.usp5"}/></li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
  )

}

const mapDispatchToProps = (dispatch) => {
  return {
    redirect: (destination) => dispatch(redirect(destination))
  };
};

const mapStateToProps = (state) => {
  return {
    showError: state.root.showError,
    errorMessage: state.root.errorMessage,
  };
};

const Register = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterComponent);

export default injectIntl(Register);
