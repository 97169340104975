import {defineMessages} from 'react-intl';

const namespace = 'gocardless.error.page.';

export default defineMessages({
  title: {
    id: `${namespace}title`,
    description: 'Error title',
    defaultMessage: 'Uh oh...',
  }, message: {
    id: `${namespace}message`,
    description: 'Error title',
    defaultMessage: 'Leider wurde die Verbindung Ihres Lieferanten zwischen Sepaheld und GoCardless aufgehoben. Bitte wenden Sie sich an Ihren Lieferanten um dies zu beheben.',
  },
  help: {
    id: `${namespace}help`,
    description: 'HELP HINT',
    defaultMessage: 'Tipp für Lieferanten: Auf Ihrem Sepaheld-Dashboard die Verbindung zu GoCardless aufheben und neu verbinden.',
  },
  furtherInformation: {
    id: `${namespace}further.information`,
    description: 'HELP HINT',
    defaultMessage: 'Sie wünschen weitere Informationen zu Sepaheld?',
  }

});
