import React from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import internationalization from "./intl";

import {LazyLoadImage} from "react-lazy-load-image-component";
import Background from "../images/compressed/AdobeStock_133775061.png";

const ErpUnauthenticated = () => {

  return <div>
    <>
      <div className="d-lg-flex half">
        <div className="contents lorder-1 order-md-1">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-7">
                <div className="mb-4">
                  <h1 className={"mb-4"}><FormattedMessage {...internationalization.title}/></h1>
                  <h5 className="mb-5"><FormattedMessage {...internationalization.message}/></h5>
                  <p className="mb-4"><FormattedMessage {...internationalization.help}/>

                  </p>
                  <p><a href="https://sepaheld.com/faq/">FAQs</a></p>
                  <p><a href="https://sepaheld.com/"><FormattedMessage {...internationalization.furtherInformation}/></a></p>

                </div>


              </div>
            </div>
          </div>
        </div>

        <div className="bg order-2 order-md-2 d-none d-lg-block">
          <LazyLoadImage
              wrapperClassName={"h-100 d-inline-block"}
              style={{minHeight: '100%', maxWidth: '100%', maxHeight: '100%', objectFit: 'cover'}}
              effect="blur"
              src={Background}
          />
        </div>

      </div>

    </>

  </div>

}

export default injectIntl(ErpUnauthenticated);
