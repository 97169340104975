import {
  deleteGoCardlessWithCustomerEndpoint,
  getGoCardlessWithCustomerEndpoint,
  postGoCardlessWithCustomerEndpoint
} from "./GoCardlessRequester";
import {get, post} from './Requester';
import {getLexofficeCoreWithCustomerEndpoint, postLexofficeCoreWithCustomerEndpoint} from "./LexofficeCoreRequester";

export function getProfileGoCardless() {
    return new Promise((resolve, reject) => {
        getGoCardlessWithCustomerEndpoint(`profile`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function removeFlow(flowId) {
    return new Promise((resolve, reject) => {
        deleteGoCardlessWithCustomerEndpoint('flows/' + flowId)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function registerGoCardless(code) {
    return new Promise((resolve, reject) => {
        get('connect/gocardless/redirect?code=' + code)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function retriggerMandate(id) {
    return new Promise((resolve, reject) => {
        post("gocardless/flows/" + id + "/trigger").then((response) => {
            return response.data;
        })
    });
}

export function getContactsWithMandateStatus() {
    return new Promise((resolve, reject) => {
        getLexofficeCoreWithCustomerEndpoint('contacts/mandates')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getGocardlessPayouts() {
    return new Promise((resolve, reject) => {
        getGoCardlessWithCustomerEndpoint('payouts')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function transferPaymentByIdToBackend(payoutId) {
    return new Promise((resolve, reject) => {
        postGoCardlessWithCustomerEndpoint('payouts/' + payoutId)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getContactsWithMandateStatusPaging(page, pageSize) {
    return new Promise((resolve, reject) => {
        getLexofficeCoreWithCustomerEndpoint(
            'contacts/mandates?page=' + page + '&size=' + pageSize
            + '&sort=asc&sortBy=name')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getContactsWithMandateStatusPagingAndSearch(page, pageSize, searchText) {
    return new Promise((resolve, reject) => {
        getLexofficeCoreWithCustomerEndpoint(
            'contacts/mandates?page=' + page + '&size=' + pageSize
            + '&sort=asc&sortBy=name&name=' + searchText)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function createMandateForContactId(id) {
    return new Promise((resolve, reject) => {
        postLexofficeCoreWithCustomerEndpoint("contacts/create/" + id + "/mandate")
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function createMandateForContactIdWithoutEmail(id) {
    return new Promise((resolve, reject) => {
        postLexofficeCoreWithCustomerEndpoint(
            "contacts/create/" + id + "/mandate/without/email")
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getMandates() {
    return new Promise((resolve, reject) => {
        getGoCardlessWithCustomerEndpoint(`flows`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function postProfileGoCardless() {
    return new Promise((resolve, reject) => {
        postGoCardlessWithCustomerEndpoint(`profile`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function postGoCardlessCustomerConfigure(redirectUrl) {
    return new Promise((resolve, reject) => {
        postGoCardlessWithCustomerEndpoint(`configure`, {redirectUrl})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getGoCardlessCustomerMandates() {
    return new Promise((resolve, reject) => {
        getGoCardlessWithCustomerEndpoint(`flows`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
