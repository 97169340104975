import {useState} from 'react';

const useNewPasswordForm = (messages, handleSubmit) => {

  const [email, setEmail] = useState(undefined);
  const [error, setError] = useState();

  const handleFormSubmit = () => {
    //1. check if Email already exists
    if (!email) {
      setError({
        errorMessageId: messages["emailIsRequired"],
        showMissingInfoErrorMessage: true,
      });
      return
    }
    handleSubmit(email);
  }

    return {
      email,
      error,
      setEmail,
      handleFormSubmit
    };
}

export default useNewPasswordForm;