import config from '../config/config';
import {logout} from './LogoutService';
import {jwtToken as getJwtToken} from './services/LoginService';
import {
    BASEURL,
    SERVICE_DOWN_CODES,
    SERVICE_DOWN_PREV_URL_KEY
} from "../constants/constants";
import axios from "axios";

function base_options(endpoint, opts = {}) {
    let jwtToken = getJwtToken();

    while (endpoint.indexOf('//') >= 0) {
        endpoint = endpoint.replace(/\/\//g, '/');
    }

    let url = config.API_BASE_URL + endpoint;

    return {
        url: url,
        encoding: opts.encoding ? opts.encoding : undefined,
        headers: {
            'Content-Type': opts.file ? 'multipart/form-data'
                : 'application/json',
            Authorization: 'Bearer ' + jwtToken,
        },
        transform: function (body, response) {
            if (
                response.headers['content-type'] &&
                response.headers['content-type'].includes('application/json')
            ) {
                response.data = JSON.parse(body);
            }
            return response;
        },
    };
}

function request(options, opts = {}) {
    return new Promise((resolve, reject) => {
        axios(options)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            if (error && error.error) {
                let parsedError;

                try {
                    parsedError = JSON.parse(error.error);
                } catch (e) {
                    parsedError = undefined;
                }

                /** if we need to logout!
                 error: "Forbidden"
                 message: ""
                 path: "/customers/cd05638d-19a2-47b2-83f1-47a0ef8196b1/teams/is-admin"
                 status: 403
                 timestamp: "2020-11-26T15:00:41.787+00:00"
                 */

                /** if we need NOT to logout!
                 errorCode: 403303
                 errorDetails: ["BusinessRuleException([ACCESS_DENIED_FEATURES_MATR…customerId: cd05638d-19a2-47b2-83f1-47a0ef8196b1)"]
                 errorMessage: "ACCESS_DENIED_FEATURES_MATRIX_MAX_FLOWS"
                 httpStatus: 403
                 timestamp: "2020-11-26T15:02:43.886+00:00"
                 */

                if (parsedError
                    && opts.checkAuthorization
                    && (error.statusCode === 401 || error.statusCode === 403)
                    && !parsedError.errorCode
                ) {
                    logout();
                }
                if (parsedError
                    && (SERVICE_DOWN_CODES.includes(error.statusCode))
                ) {
                    localStorage.setItem(SERVICE_DOWN_PREV_URL_KEY,
                        window.location.pathname);
                    window.location.href = `${BASEURL}/service-down`;
                }
            }

                reject(error);
            });
    });
}

export function get(endpoint, opts = {checkAuthorization: true}) {
    let options = {
        method: 'GET',
        ...base_options(endpoint, opts),
    };
    return request(options, opts);
}

function convertBody(body, opts = {}) {
    if (opts.file) {
        return undefined;
    } else if (typeof body === 'object') {
        return JSON.stringify(body);
    } else {
        return '' + body;
    }
}

export function post(endpoint, body, opts = {checkAuthorization: true}) {
    let options = {
        method: 'POST',
        ...base_options(endpoint, opts),
        data: convertBody(body),
        multipart: opts.file ? body : undefined,
    };
    return request(options, opts);
}

export function put(endpoint, body, opts = {checkAuthorization: true}) {
    let options = {
        method: 'PUT',
        ...base_options(endpoint, opts),
        data: !opts.file ? JSON.stringify(body) : undefined,
        multipart: opts.file ? body : undefined,
    };
    return request(options, opts);
}

export function del(endpoint, opts = {checkAuthorization: true}) {
    let options = {
        method: 'DELETE',
        ...base_options(endpoint, opts),
    };
    return request(options, opts);
}
