import React from 'react'

import {FormattedMessage} from 'react-intl'

import CookieConsent from "react-cookie-consent";

// CookieConsent docs: https://www.npmjs.com/package/react-cookie-consent
const ConsentWrapper = ({children, className = '', header}) => {
    return (
        <React.Fragment>
            <CookieConsent
                debug={false}

                contentClasses="consent-custom-content"
                containerClasses="consent-custom-container"
                buttonClasses="mint-button-style consent-btn-custom"

                buttonText={ <FormattedMessage id="cookie.consent.btn.agree.text" /> }
                declineButtonText={ <FormattedMessage id="cookie.consent.btn.decline.text" /> }

                flipButtons={ true }
                enableDeclineButton
                declineButtonId="consent-btn-decline"
            >
              <FormattedMessage id="cookie.consent.description.text"
                                description="Consent description text"/>
            </CookieConsent>
        </React.Fragment>
    )
}

export default ConsentWrapper
