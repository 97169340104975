import initialState from '../constants/store-initial-state';

import {
    IS_INTERACTION_ENABLED,
    IS_LOADING,
    PLAN_CHANGED_MODAL,
    REDIRECT,
    UPDATE_CONTACT_POPUP,
    UPDATE_DAYS_REMAINING,
    UPDATE_ERROR_MESSAGE,
    UPDATE_ERROR_MODAL,
    UPDATE_GLOBAL_ERROR_HANDLING_CHILDREN,
    UPDATE_LOADED,
    UPDATE_LOGIN_USER,
    UPDATE_REGISTRATION_CLIENT,
    UPDATE_REGISTRATION_USER,
    UPDATE_REQUESTED_OPERATIONS,
    UPDATE_REQUESTED_OPERATIONS_POPUP_INTL_TEXT_OBJECT,
    UPDATE_SHOW_UPGRADE_MODAL,
    UPDATE_STEP,
    UPDATE_TRIAL_ENDED_POPUP,
    UPDATE_TRIAL_REMAINING_POPUP,
    UPDATE_USER,
    UPDATE_USER_CURRENT_CUSTOMER,
} from '../constants/action-types';

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case REDIRECT:
            return {
                ...state,
                redirect: action.payload,
            };

        case UPDATE_STEP:
            return {
                ...state,
                registerStep: action.payload,
            };

        case IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case UPDATE_REGISTRATION_USER:
            return {
                ...state,
                registrationUser: action.payload,
            };

        case UPDATE_REGISTRATION_CLIENT:
            return {
                ...state,
                registrationClient: action.payload,
            };

        case UPDATE_LOGIN_USER:
            return {
                ...state,
                loginUser: action.payload,
            };

        case UPDATE_USER:
            return {
                ...state,
                user: action.payload,
            };

        case UPDATE_USER_CURRENT_CUSTOMER:
            return {
                ...state,
                user: {
                    ...state.user,
                    currentCustomer: action.payload,
                },
            };

        case IS_INTERACTION_ENABLED:
            return {
                ...state,
                isUserInteractive: action.payload,
            };

        case UPDATE_REQUESTED_OPERATIONS:
            return {
                ...state,
                requestedOperations: action.payload,
            };

        case UPDATE_REQUESTED_OPERATIONS_POPUP_INTL_TEXT_OBJECT:
            return {
                ...state,
                requestedOperationsIntlTextObject: action.payload,
            };

        case UPDATE_SHOW_UPGRADE_MODAL:
            return {
                ...state,
                showUpgradeModal: action.payload,
            };

        case PLAN_CHANGED_MODAL:
            return {
                ...state,
                planChanged: action.payload,
            };

        case UPDATE_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload,
            };

        case UPDATE_ERROR_MODAL:
            return {
                ...state,
                showError: action.payload,
            };

        case UPDATE_CONTACT_POPUP:
            return {
                ...state,
                showContactPopup: action.payload,
            };

        case UPDATE_DAYS_REMAINING:
            return {
                ...state,
                daysRemaining: action.payload,
            };

        case UPDATE_TRIAL_ENDED_POPUP:
            return {
                ...state,
                showTrialEndedPopup: action.payload,
            };

        case UPDATE_TRIAL_REMAINING_POPUP:
            return {
                ...state,
                showTrialRemainingPopup: action.payload,
            };

        case UPDATE_LOADED:
            return {
                ...state,
                loaded: action.payload,
            };

        case UPDATE_GLOBAL_ERROR_HANDLING_CHILDREN:
            return {
                ...state,
                globalErrorHandleChildren: action.payload,
            };

        default:
            return state;
    }
};

export default rootReducer;
