import React from 'react';
import imgSparkle1 from "../images/ServiceDown/sparkle-1.png";
import imgSparkle2 from "../images/ServiceDown/sparkle-2.png";
import {FormattedMessage, injectIntl} from "react-intl";
import "../../styles/service-down.scss";
import internationalisation from "./intl";
import {useServiceDownHooks} from "./hooks";

export const ServiceDown = ({history}) => {

  const {testAPI} = useServiceDownHooks({history});

  return (
    <div className="auth-wrapper offline">
      <div className="offline-wrapper">
        <img src={imgSparkle1} alt="User-1" className="img-fluid s-img-1"/>
        <img src={imgSparkle2} alt="User-2" className="img-fluid s-img-2"/>
        <div className="container off-main">
          <div className="row justify-content-center">
            <div className="col-6">
              <div className="text-center">
                <div className="moon"/>
                <img src={imgSparkle2} alt="" className="img-fluid boat-img"/>
              </div>
            </div>
          </div>
          <div className="row m-0 justify-content-center off-content">
            <div className="sark">
              <img src={imgSparkle2} alt="" className="img-fluid img-sark"/>
              <div className="bubble"/>
            </div>
            <div className="col-sm-12 p-0">
              <div className="text-center">
                <h1 className="text-white text-uppercase"><FormattedMessage {...internationalisation.offline} /></h1>
                <h5 className="text-white font-weight-normal m-b-30"><FormattedMessage {...internationalisation.siteIsDown} /></h5>
                <button onClick={() => testAPI()} className="btn btn-warning mb-4 text-center">
                  <FormattedMessage {...internationalisation.reload} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <svg width="100%" height="70%" version="1.1" xmlns="http://www.w3.org/2000/svg" className="wave">
          <title></title>
          <defs/>
          <path id="feel-the-wave" d=""/>
        </svg>
        <svg width="100%" height="70%" version="1.1" xmlns="http://www.w3.org/2000/svg" className="wave">
          <title></title>
          <defs/>
          <path id="feel-the-wave-two" d=""/>
        </svg>
      </div>
    </div>
  )
}

export default injectIntl(ServiceDown);
