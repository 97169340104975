import {useEffect, useState} from 'react';
import {decodeJwtToken, isJwtTokenExpired} from "../../../js/Common";
import {getForgottenPassword, postChangePassword} from "../../../js/User";
import {useLocation, useNavigate} from "react-router-dom";
import {loginUser} from "../../../js/services/LoginService";

const useResetPassword = () => {

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get('jwtToken');
  const [tokenIsValid, setTokenIsValid] = useState(!isJwtTokenExpired(token));

  useEffect(() => {
    if (tokenIsValid) {
      getForgottenPassword(token)
        .catch(error => {
          setTokenIsValid(false);
        })
    }
  }, [token, tokenIsValid]);


  const handleSubmit = ({password, confirmPassword}) => {
    postChangePassword(token, password)
      .then(result => {
        const tokenPayload = decodeJwtToken(token);
        return loginUser({email: tokenPayload.sub, password})
          .then(result => {
            navigate('/');
          })
      })
      .catch(error => {
        console.error('TODO: handle error', error)
      })
  }

  return {
    tokenIsValid,
    handleSubmit
  };
}

export default useResetPassword;
