import { CUSTOMER_KEY, JWT_KEY, USER_KEY, STAY_LOGGED_IN_KEY, STAY_LOGGED_IN } from "../../constants/constants";
import { login } from "../../Actions/Login";

/**
 * @param {{email:string,password:string}} credentials
 */
export async function loginUser(credentials) {
  clearCredentialsStorage();
  const response = await login(credentials);
  updateCredentialsStorage({
    jwtToken: response?.jwtToken,
    userId: response?.user.id,
    customerId: response?.user?.customers[0]?.id
  });
  return response;
}
/**
 * @returns a value from localStorage or default value
 */
export function stayLoggedInPreference() {
  const stayLoggedIn = localStorage.getItem(STAY_LOGGED_IN_KEY);
  switch (stayLoggedIn) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return STAY_LOGGED_IN;
  }
}
/**
 * Stores user's preference in local storage and transfers user's credentials to the right storage.
 * @param {boolean} stayLoggedIn 
 */
export function setStayLoggedInPreference(stayLoggedIn) {
  const current = stayLoggedInPreference();
  if (stayLoggedIn === current) {
    return;
  }
  localStorage.setItem(STAY_LOGGED_IN_KEY, stayLoggedIn);
  const fromStorage = current ? localStorage : sessionStorage;
  const toStorage = stayLoggedIn ? localStorage : sessionStorage;
  const credentials = {
    jwtToken: fromStorage.getItem(JWT_KEY),
    userId: fromStorage.getItem(USER_KEY),
    customerId: fromStorage.getItem(CUSTOMER_KEY)
  }
  updateStorage(toStorage, credentials)
  clearStorage(fromStorage);
}
/**
 * @returns {string|null} JWT token from session or local storage if one exists
 */
export function jwtToken() {
  return sessionStorage.getItem(JWT_KEY) || localStorage.getItem(JWT_KEY)
}
/**
 * @returns {string|null} customer id from session or local storage if one exists
 */
export function customerId() {
  return sessionStorage.getItem(CUSTOMER_KEY) || localStorage.getItem(CUSTOMER_KEY)
}
/**
 * @returns {string|null} user id from session or local storage if one exists
 */
export function userId() {
  return sessionStorage.getItem(USER_KEY) || localStorage.getItem(USER_KEY)
}
/**
 * @param {object} storage to clear user's credentials from
 */
function clearStorage(storage) {
  storage.removeItem(JWT_KEY)
  storage.removeItem(CUSTOMER_KEY)
  storage.removeItem(USER_KEY)
}
/**
 * @param {object} storage
 * @param {{jwtToken?:string,userId?:string,customerId?:string}} credentials
 */
function updateStorage(storage, { jwtToken, userId, customerId }) {
  if (typeof jwtToken === 'string' && jwtToken.length) {
    storage.setItem(JWT_KEY, jwtToken);
  }
  if (typeof userId === 'string' && userId.length) {
    storage.setItem(USER_KEY, userId);
  }
  if (typeof customerId === 'string' && customerId.length) {
    storage.setItem(CUSTOMER_KEY, customerId);
  }
}
/**
 * removes user's credentials from local and session storage
 */
export function clearCredentialsStorage() {
  clearStorage(sessionStorage);
  clearStorage(localStorage);
}
/**
 * @param {{jwtToken?:string,userId?:string,customerId?:string}} credentials
 */
export function updateCredentialsStorage({ jwtToken, userId, customerId }) {
  clearCredentialsStorage();
  const storage = stayLoggedInPreference() ? localStorage : sessionStorage;
  updateStorage(storage, {
    jwtToken,
    userId,
    customerId
  });
}