import { customerId as getCustomerId } from './services/LoginService';
import {
    del,
    get,
    post,
    put,
} from './Requester';

export function preAppendCustomer(endpoint) {
    let customerId = getCustomerId();
    if (endpoint.startsWith('/')) {
        endpoint = endpoint.substring(1);
    }
    return 'customers/' + customerId + '/' + endpoint;
}

export function getCustomerEndpoint(endpoint, opts = {checkAuthorization: true}) {
    return get(preAppendCustomer(endpoint), opts);
}

export function postCustomerEndpoint(endpoint, body = {}, opts = {checkAuthorization: true}) {
    return post(preAppendCustomer(endpoint), body, opts);
}

export function putCustomerEndpoint(endpoint, body = {}, opts = {checkAuthorization: true}) {
    return put(preAppendCustomer(endpoint), body, opts);
}

export function deleteCustomerEndpoint(endpoint, body = {}, opts = {checkAuthorization: true}) {
    return del(preAppendCustomer(endpoint), opts);
}
