import React from "react";
import cx from "classnames";
import {FormattedMessage, injectIntl} from "react-intl";
import internationalisation from "./intl";
import formIntl from "../../../Login/intl";
import {Button} from "react-bootstrap";
import useNewPasswordForm from "./hooks";
import {useNavigate} from "react-router-dom";

const SetNewPasswordForm = ({handleSubmit}) => {

  const navigate = useNavigate();

  const messages = {
    emailIsRequired: "password.forgot.email.required"
  };

  const {
    email,
    error,
    setEmail,
    handleFormSubmit
  } = useNewPasswordForm(messages, handleSubmit);

  const fieldNotEmpty = () => (email?.length) ? 'field--not-empty' : '';

  return <>
    <div className={cx(["form-group last first", fieldNotEmpty(), "mb-5"])}>
      <label htmlFor="email"><FormattedMessage {...internationalisation.email} /></label>
      <input type="text" className="form-control" id="email" onChange={(e) => {
        setEmail(e.target.value);
      }}/>
    </div>

    {error && (<div className="alert alert-warning">
      <FormattedMessage id={"" + error.errorMessageId}/>
    </div>)
    }

    <button className="btn btn-block btn-primary"
            onClick={() => handleFormSubmit()}>
      <FormattedMessage {...internationalisation.resetPassword} />
    </button>
    <span className="d-block text-center my-4 text-muted">&mdash;
      <FormattedMessage {...formIntl.orLabel}/> &mdash;</span>

    <div className="social-login">
      <Button
          className="btn d-flex justify-content-center align-items-center w-100"
          onClick={() => navigate('/login')}>
        <FormattedMessage {...formIntl.login} />
      </Button>

    </div>
  </>
}

export default injectIntl(SetNewPasswordForm);
