import {defineMessages} from 'react-intl';

const namespaceReset = 'password.reset.';

export default defineMessages({
  password:{
    id: `${namespaceReset}password`,
    description: 'Password label',
    defaultMessage: 'Password',
  },
  confirmPassword:{
    id: `${namespaceReset}confirm.password`,
    description: 'Confirm Password label',
    defaultMessage: 'Confirm Password',
  },
  saveNewPassword:{
    id: `${namespaceReset}save.new.password`,
    description: 'Save new Password button',
    defaultMessage: 'Save new Password',
  },
})