import React, {useEffect} from 'react';
import {updateCredentialsStorage} from "../../js/services/LoginService";
import "react-lazy-load-image-component/src/effects/blur.css";
import {useNavigate} from "react-router-dom";

const LoginService = (props) => {

  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const jwtToken = urlParams.get('jwtToken');
    const userId = urlParams.get('userId');
    const customerId = urlParams.get('customerId');

    //console.log(jwtToken, userId, customerId);
    if(!jwtToken || !userId || !customerId) {
      navigate('/login');
    }

    const credentials = {
      jwtToken: jwtToken,
      userId: userId,
      customerId: customerId
    }
    updateCredentialsStorage(credentials);
    navigate('/app');


  }, [navigate]);

  return (
      <>
      </>
  );
}

export default LoginService;
