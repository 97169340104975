import {
    REDIRECT,
} from '../constants/action-types';

export function redirect(destination, body) {
    return {
        type: REDIRECT,
        payload: {
            destination,
            body,
        },
    };
}

