import React from "react"
import { FormattedMessage, injectIntl } from "react-intl";
import Background from "../../images/compressed/AdobeStock_133775821.png";
import {Link} from "react-router-dom";
import ResetPasswordForm from "./ResetPasswordForm";
import internationalisation from "./intl";
import useResetPassword from "./hooks";
import "react-lazy-load-image-component/src/effects/blur.css";
import {LazyLoadImage} from "react-lazy-load-image-component";

const ResetPassword = (props) => {

  const {
    tokenIsValid,
    handleSubmit,
  } = useResetPassword();

  return (
    <>
      <div className="d-lg-flex half">
        <div className="contents lorder-1 order-md-1">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-7">
                <div className="mb-4">
                  <h3><FormattedMessage {...internationalisation.passwordReset} /></h3>
                  <p className="mb-4"><FormattedMessage {...internationalisation.resetInstructions} /></p>
                </div>

                {tokenIsValid ? <ResetPasswordForm handleSubmit={handleSubmit} /> :
                  <>
                    <div><FormattedMessage {...internationalisation.tokenExpired} /></div>
                    <Link to="/forgotten-password" replace><FormattedMessage {...internationalisation.forgottenPasswordLink} /></Link>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="bg order-2 order-md-2 d-none d-sm-block">
          <LazyLoadImage
            wrapperClassName={"h-100 d-inline-block"}
            style={{minHeight:'100%', maxWidth:'100%', maxHeight:'100%', objectFit: 'cover'}}
            effect="blur"
            src={Background}
          />
          </div>
      </div>
    </>
  )
}

export default injectIntl(ResetPassword);