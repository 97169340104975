import {useCallback, useEffect} from "react";
import config from "../../config/config";
import {SERVICE_DOWN_PREV_URL_KEY} from "../../constants/constants";
import {useNavigate} from "react-router-dom";

export const useServiceDownHooks = ({history}) => {

  const navigate = useNavigate();

  const testAPI = useCallback(() => {
    fetch(
        `${config.API_BASE_URL}register/check-email?email=testAPI@example.com`)
    .then((response) => {
      if (response.status !== 200) {
        console.log("Service down: " + response.status);
        return;
      }
      const url = window.localStorage.getItem(SERVICE_DOWN_PREV_URL_KEY);
      window.localStorage.removeItem(SERVICE_DOWN_PREV_URL_KEY);
      navigate(url);
    })
    .catch((err) => {
      console.log("Fetch Error :-S", err);
    })
  }, [navigate])

  useEffect(() => {
    const id = setInterval(() =>
        testAPI()
      , 7000)

    return () => clearInterval(id);
  }, [testAPI]);

  return {
    testAPI
  }
}
