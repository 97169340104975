import { REGISTER_STEP_KEY } from "../../constants/constants";
import store from "../../js/Store";
import { redirect } from "../../js/Actions";
import { clearCredentialsStorage } from "./LoginService";

export function storageCleanup() {
  clearCredentialsStorage();
  window.sessionStorage.removeItem(REGISTER_STEP_KEY)
}

export function logout() {
  storageCleanup();
  store.dispatch(redirect('/login'))
}
