import {applyMiddleware, compose, createStore} from 'redux';
import globalReducer from './GlobalReducer';
import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const logger = createLogger();

const middleware = [thunk];

if (process.env.NODE_ENV !== 'production') {
    middleware.push(logger);
}

const store = createStore(
    globalReducer,
    storeEnhancers(
        applyMiddleware(...middleware),
    ),
);

export default store
