import {useState} from 'react';
import {checkWhetherEmailExists} from "../../../js/EmailService";
import {postForgottenPassword} from "../../../js/User";

const useSetNewPassword = () => {

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleSubmit = (email) => {
    checkWhetherEmailExists(email)
      .then((response) => {
        if (response && response.registered) {
          return postForgottenPassword(email)
            .then(result => {
              setSubmitSuccess(true);
            })
        } else {
          setSubmitSuccess(true);
        }
      })
      .catch(error => {
        console.error('TODO: handle error', error)
      })
  }

    return {
      submitSuccess,
      setSubmitSuccess,
      handleSubmit
    };
}

export default useSetNewPassword;