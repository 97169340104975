export function isJwtTokenExpired(jwtToken) {
    if (!jwtToken) {
        return true;
    }
    try {
        const jwtDecode = require('jwt-decode');
        const decodedToken = jwtDecode(jwtToken);
        const dateNow = new Date();

        if (decodedToken.exp * 1000 < dateNow.getTime()) {
            return true;
        }

        return false;
    } catch (e) {
        return true;
    }
}

export function decodeJwtToken(jwtToken) {
    if (!jwtToken) {
        return;
    }
    try {
        const jwtDecode = require('jwt-decode');
        return jwtDecode(jwtToken);

    } catch (e) {
        return;
    }
}
