import {
  getCustomerEndpoint,
  postCustomerEndpoint,
  putCustomerEndpoint
} from "./CustomerRequester";

export function getCustomerContractPlan() {
  return getCustomerEndpoint(`contract`)
      .then((response) => {
        return response.data;
      })
}

export function getCurrentContract() {
  return getCustomerEndpoint(`contract/current`)
      .then((response) => {
        return response.data;
      })
}

export function postCustomerContractPlan(contractPlanId) {
  return new Promise((resolve, reject) => {
    postCustomerEndpoint(`contract`, {contractPlanId, checkAuthorization: true})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
  });
}

export function putCustomerContractPlan(contractPlanId){
  return new Promise((resolve, reject) => {
    putCustomerEndpoint(`contract`, {contractPlanId, checkAuthorization: true})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
  })
}

export function getCustomerContractInvoice(contractPlanId){
  return new Promise((resolve, reject) => {
    getCustomerEndpoint(`contract/invoice`, {contractPlanId, checkAuthorization: true})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
  })
}

export function postCustomerContractInvoice(){
  return new Promise((resolve, reject) => {
    postCustomerEndpoint(`contract/payment`, {checkAuthorization: true})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
  })
}
