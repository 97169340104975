// Keys from localStorage
export const JWT_KEY = 'jwt';
export const USER_KEY = 'user';
export const CUSTOMER_KEY = 'customer';
export const INTEGRATION = 'integration';
export const SEVDESK_STRING = 'sevdesk';
export const LEXOFFICE_STRING = 'lexoffice';
export const SERVICE_DOWN_PREV_URL_KEY = 'previousUrl';
export const TEAM_KEY = 'team';
export const EMAIL_DOMAIN = 'EMailDomain';
export const STAY_LOGGED_IN_KEY = "stayLoggedIn";
export const STAY_LOGGED_IN = false;

// ids
export const DOCUMENT_PREVIEW_PORTAL = 'documentPreviewPortal';

// Keys from window.sessionStorage
export const REGISTER_STEP_KEY = 'registerStep';

export const APPTYPS = {
  LEXOFFICE: 'LEXOFFICE',
  GOCARDLESS: 'GOCARDLESS',
  SEVDESK: 'SEVDESK',
  DATEV: 'DATEV'
};

export const BASEURL = process.env.REACT_APP_FRONTEND_BASE_URL;
export const APP_PATH = "/app"

export const SERVICE_DOWN_CODES = [503];
