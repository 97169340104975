import { customerId as getCustomerId } from './services/LoginService';
import {
    del,
    get,
    post,
    put,
} from './Requester';

export function preAppendGoCardlessWithCustomer(endpoint) {
    let customerId = getCustomerId();
    if (endpoint.startsWith('/')) {
        endpoint = endpoint.substring(1);
    }
    return 'gocardless/' + customerId + '/' + endpoint;
}

export function getGoCardlessWithCustomerEndpoint(endpoint, opts = {checkAuthorization: true}) {
    return get(preAppendGoCardlessWithCustomer(endpoint), opts);
}

export function postGoCardlessWithCustomerEndpoint(endpoint, body = {}, opts = {checkAuthorization: true}) {
    return post(preAppendGoCardlessWithCustomer(endpoint), body, opts);
}

export function putGoCardlessWithCustomerEndpoint(endpoint, body = {}, opts = {checkAuthorization: true}) {
    return put(preAppendGoCardlessWithCustomer(endpoint), body, opts);
}

export function deleteGoCardlessWithCustomerEndpoint(endpoint, body = {}, opts = {checkAuthorization: true}) {
    return del(preAppendGoCardlessWithCustomer(endpoint), opts);
}
